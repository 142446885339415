<template>
  <!-- Error page-->
  <div>
    <b-row class="justify-content-center mt-3" >
      <b-col md="3" lg="3">
        <b-row>
          <h4>Hola <b>{{giftResume.investment.nameReceiver}}</b>,<br><br> Tienes un regalo de <b>{{parseFloat(giftResume.investment.amountInvested).toFixed(2).toString().replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,")}} COP </b> en ganado en el proyecto <b>{{giftResume.project.projectCode}}</b>, de parte de <b>{{giftResume.investorDetails.firstName}} {{giftResume.investorDetails.lastName}}</b></h4>
        </b-row>    
      </b-col>
    </b-row>
    <b-row v-if="(this.giftResume.receiverAlreadyExist==false)" class="justify-content-center" >
      <b-col md="3" lg="3">
        <b-row>
          <h5><br>Para aceptar tu regalo necesitamos que digites la siguiente información, y así podremos crear tu usuario:<br></h5>
        </b-row>
        <b-row>
          <b-col>            
            <b-form-group label-for="basicInput">                        
                <label for="firstName">Nombre</label>
                <b-form-input v-model="firstName"/>

                <label for="lastName">Apellido</label>
                <b-form-input v-model="lastName"/>
                
                <label for="email">Email</label>
                <b-form-input v-model="email"/>

                <label for="phoneNumber">Celular</label>
                <b-form-input v-model="phoneNumber"/>

                <label for="password">Contraseña</label>
                <b-form-input v-model="password"/>
            </b-form-group>
          </b-col>            
        </b-row>        
      </b-col>
    </b-row>  

    <b-row class="justify-content-center">      
      <b-col md="3" lg="3">
          <b-row>
            <b-col>
              <b-button class="mt-1" variant="primary" block @click="acceptGiftInvestment">
                  <feather-icon icon="GiftIcon" class="mr-50"/>
                  <span class="align-middle">Aceptar regalo</span>
              </b-button>
            </b-col>
        </b-row>
      </b-col>     
    </b-row>

  </div>

</template>


<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg, BSpinner,BRow, BCol, BFormInput, BFormTextarea, BFormGroup } from 'bootstrap-vue'
import store from '@/store/index'
import axios from '@axios'
import { isUserLoggedIn, getUserData, getHomeRouteForLoggedInUser, getAbilitiesBasedOnUserType } from '@/auth/utils'
import useJwt from '@/auth/jwt/useJwt'


export default {
  components: {        
      BLink,
      BButton,
      BImg,
      BSpinner,
      BRow,
      BCol,
      BFormInput,
      BFormTextarea,
      BFormGroup,
      
  },
  data() {
    return {
      downImg: require('@/assets/images/pages/sosty-login.png'),
      redirectToProjectCode: null,
      email: null,
      user:{},
      projectCode: null,      
      investmentID: null,
      activateForm: null,
      nameReceiver: null,
      message: null,
      firstName: null,
      lastName: null,
      password: null,
      phoneNumber:null,
      newUser: {},
      userID: null,      
      giftResume: {},
      processing: false,
      receiverUserID: null,
    }
  },
  created() {

    if(this.$route != null && this.$route.query != null && this.$route.query.investmentID != null) {
      this.investmentID = this.$route.query.investmentID;      
    }

    this.loadGiftResume()
 
  },

  computed: {
      imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {            
          this.downImg = require('@/assets/images/pages/sosty-login.png')
          return this.downImg
      }
      return this.downImg
      },
  },

  methods:{
    loadGiftResume(){
      axios.get('/Investment/GetGiftResume', { params: { investmentID: this.investmentID }})
      .then(response => {
        if(response.data != null){
          this.giftResume = response.data;  
          this.email = response.data.investment.emailReceiver;                  
          if(response.data.receiverAlreadyExist == false){
            this.firstName = response.data.investment.nameReceiver;
          }
          if(response.data.investment.receiverUserID != null){
            this.$router.replace({path: 'my-investments'});
          }
        }
      })
    },

    acceptGiftInvestment(){
      if(this.giftResume.receiverAlreadyExist == false && (!this.firstName || 
          !this.lastName || 
          !this.email || 
          !this.password || 
          !this.phoneNumber)){
        this.$bvToast.toast("Debes diligenciar todos los campos", { title: 'Error', variant: 'danger', solid: true, });
        return;
      }
      var payload = {
        userID: this.userID,
        investmentID: this.investmentID,
        firstName : this.firstName,
        lastName : this.lastName,
        email : this.email,
        password : this.password,
        phoneNumber: this.phoneNumber
      }

      axios.post('/Investment/AcceptGiftInvestment', payload)
      .then(response => {          
          if(response.data){
            this.processing = false;       
            this.data = response.data;
            useJwt.setToken(response.data.accessToken)
            var abilities = getAbilitiesBasedOnUserType(response.data.user.userType);          
            response.data.user.ability = abilities;
            localStorage.setItem('userData', JSON.stringify(response.data.user));          
            this.$ability.update(abilities);
            window.location.reload()
          }          
        }) 
        .catch(error => {
          this.processing = false;
            if(error.response){
              this.$bvToast.toast(error.response.data, { title: 'Error', variant: 'danger', solid: true, })
            } else {
              this.$bvToast.toast(JSON.stringify(error), { title: 'Error', variant: 'danger', solid: true, })
            }
        })       
    },

  }
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
